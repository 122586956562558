<template>
  <div class="basic-info" style="margin-bottom:30px">
    <div class="header">
      <div>
        <el-page-header @back="goBack" :content="name + ' 返佣详情'">
        </el-page-header>
      </div>
    </div>
    <div class="text item">
      <Divider :title="'基本信息'"></Divider>
      <el-row>
        <el-col :span="12" class="text-align"
          >返佣名称：{{ basicInfo.name }}</el-col
        >
        <el-col :span="24" class="text-align"
          >返佣备注：{{ basicInfo.remark }}</el-col
        >
      </el-row>
      <div v-for="(item, index) in list" :key="index">
        <Divider :title="item.target"></Divider>
        <el-row>
          <el-col :span="12" class="text-align"
            >结算单位：每{{ item.cycleUtil }}</el-col
          >
          <el-col :span="12" class="text-align"
            >结算周期：每{{ item.cycleUtil }}
            {{ item.cycleUtil === "周" ? "星期" : "" }}{{ item.cycleNumber
            }}{{ item.cycleUtil === "月" ? "日" : "" }}</el-col
          >
        </el-row>
        <el-table :data="item.list">
          <el-table-column label="结算次数" width="150">
            <template slot-scope="scope">
              第{{ scope.$index + 1 }}次结算
            </template>
          </el-table-column>
          <el-table-column label="结算时间规则" prop="timeNumber">
            <template slot-scope="scope">{{ scope.row.tips }}</template>
          </el-table-column>
          <el-table-column label="结算比例（%）" prop="range">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from '/src/api/base';
export const RecruitReturnPlanDescribe = api('/recruitReturnPlan')(
  'recruitReturnPlan.describe.json'
);

export default {
  components: {
    Divider: () => import('/src/components/divider.vue')
  },
  data() {
    return {
      name: '',
      basicInfo: {
        name: '',
        remark: ''
      },
      list: []
    };
  },

  created() {
    this.name = this.$route.query.name;
    this.contentDescribe();
  },
  methods: {
    async contentDescribe() {
      const res = await RecruitReturnPlanDescribe({
        name: this.name
      });
      this.basicInfo.name = res.name;
      this.basicInfo.remark = res.remark;
      this.list = JSON.parse(res.content).map(it => {
        return {
          ...it,
          list: it.list.map(_it => {
            return {
              ..._it,
              tips: this.getTips(it.cycleUtil, it.cycleNumber, _it.timeNumber)
            };
          })
        };
      });
      console.log(this.list);
    },
    goBack() {
      window.history.go(-1);
    },
    getTips(cycleUtil, cycleNumber, timeNumber) {
      return (
        (timeNumber === 0 ? '本' : '今后第') +
        (timeNumber === 0 ? '' : timeNumber) +
        cycleUtil +
        (cycleUtil === '周' ? '星期' : '') +
        cycleNumber +
        (cycleUtil === '月' ? '日' : '')
      );
    }
  }
};
</script>

<style lang="scss">
.basic-info .last-breadcrumb {
  color: rgba(0, 0, 0, 0.8);
}
.basic-info .font-img {
  font-size: 26px;
  cursor: pointer;
}
.showImgDialog .imgBox {
  display: flex;
}
.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.card-header {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.card-icon {
  display: flex;
  width: 4px;
  height: 15px;
  background: #659fe5;
  margin-top: 3px;
  margin-right: 10px;
}
.card-title {
  margin-right: 8px;
}
.card-btn {
  cursor: pointer;
}
.text {
  font-size: 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}
.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.marginBottom {
  margin-bottom: 12px;
}
.statusPointer {
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
}
.aType {
  color: #659fe5;
  cursor: pointer;
}
.tooltipClas {
  width: 250px;
}
.basic-info {
  .warning {
    color: #e6a23c;
  }
  .success {
    color: #67c23a;
  }
  .danger {
    color: #f56c6c;
  }
  .info {
    color: #409eff;
  }
}
.defaultTips {
  display: inline-block;
  margin: 0 8px;
  span {
    display: inline-block;
    font-size: 12px;
    color: red;
    line-height: 32px;
    text-align: center;
  }
}
</style>
